<template>
    <div id="top" class="page-wrapper">
        <section class="hero home" v-if="$route.meta.lang === 'en'">
            <div class="hero-wrap max-padding" data-scroll data-scroll-speed="-2">
                <div class="top" data-scroll data-inview="fadeInUp" data-delay="200">
                    <h1 class="medium-title" data-scroll data-scroll-speed="1">Media</h1>
                </div>
                <img data-hero-svg-anim data-src="/static-img/innovation.svg" src="/static-img/innovation.svg" class="innovation" title="Innovation" alt="Innovation" data-scroll data-inview="fadeInUp" data-delay="300">
                <div class="bottom" data-scroll data-inview="fadeInUp" data-delay="400">
                    <h2 class="regular-text" data-scroll data-scroll-speed="-1">for <strong>ambitious brands</strong></h2>
                </div>
            </div>
            <hero-svg-cut />
        </section>
        <section class="hero home" v-if="$route.meta.lang === 'fr'">
            <div class="hero-wrap max-padding" data-scroll data-scroll-speed="1">
                <img data-hero-svg-anim data-src="/static-img/innovation.svg" src="/static-img/innovation.svg" class="innovation" title="Innovation" alt="Innovation" data-scroll data-inview="fadeInUp" data-delay="100">
                <div class="title-wrap">
                  <div class="left" data-scroll data-inview="fadeInUp" data-delay="300">
                      <h1 class="medium-title" data-scroll>Média</h1>
                  </div>
                  <div class="right" data-scroll data-inview="fadeInUp" data-delay="400">
                      <h2 class="medium-title" data-scroll>pour <strong>marques ambitieuses</strong></h2>
                  </div>
                </div>
            </div>
            <hero-svg-cut />
        </section>

        <section class="blocs max-padding" data-scroll data-scroll-speed="-0.5">
          <a :href="$t('service-link')" class="bloc bloc-1" data-scroll data-inview="fadeInUp" data-delay="500">
            <div class="img">
              <div class="line"></div>
              <img src="@/assets/img/expertise-1-new.jpg" class="cover" alt="Emerging Media ">
            </div>
            <div class="name">
              <p class="regular-text">{{ $t("Strategy and Account management") }}</p>
              <img src="@/assets/img/arrow-right.svg" class="arrow" alt="Arrow right">
            </div>
          </a>
          <a :href="$t('service-link')" class="bloc bloc-2" data-scroll data-inview="fadeInUp" data-delay="600">
            <div class="img">
              <div class="line"></div>
              <img src="@/assets/img/expertise-2-new.jpg" class="cover" alt="Tailored Audience Development">
            </div>
            <div class="name">
              <p class="regular-text">{{ $t("Campaign management") }}</p>
              <img src="@/assets/img/arrow-right.svg" class="arrow" alt="Arrow right">
            </div>
          </a>
          <a :href="$t('service-link')" class="bloc bloc-3" data-scroll data-inview="fadeInUp" data-delay="700">
            <div class="img">
              <div class="line"></div>
              <img src="@/assets/img/expertise-3-new.jpg" class="cover" alt="Performance Driven">
            </div>
            <div class="name">
              <p class="regular-text">{{ $t("Perfomance management") }}</p>
              <img src="@/assets/img/arrow-right.svg" class="arrow" alt="Arrow right">
            </div>
          </a>
        </section>

        <section class="methodology max-padding">
          <div class="wrap-title center" data-scroll data-inview="fadeInUp" data-delay="100">
            <h2 class="title"><span>{{ $t("Our Methodology") }}</span><br>{{ $t("The Innovation Loop") }}</h2>
          </div>

          <!-- Methodology Graph Component -->
          <methodology-graph />
        </section>

        <media-expertises-slider />

        <div id="logos-scroll-effect" class="scroll-effect" data-scroll data-scroll-speed="-0.5">
            <section class="logos">
              <div class="wrap-title center" data-scroll data-inview="fadeInUp" data-delay="100">
                <h2 class="title">{{ $t("Our Affiliates") }}</h2>
              </div>
            </section>
            <div class="logo-wrap" data-scroll data-inview="fadeInUp" data-delay="200">
                <div class="logos-scroll"
                    data-scroll=""
                    data-scroll-direction="horizontal"
                    data-scroll-speed="4"
                    data-scroll-target="#logos-scroll-effect"
                >
                    <div class="auto-track">
                        <img src="@/assets/img/logos/partners/amazon.svg" alt="Amazon">
                        <img src="@/assets/img/logos/partners/android-tv.svg" alt="AndroidTV">
                        <img src="@/assets/img/logos/partners/arima.png" alt="Arima">
                        <img src="@/assets/img/logos/partners/bell-media.svg" alt="Bell Media">
                        <img src="@/assets/img/logos/partners/cloud-raker.png" alt="Cloud Raker">
                        <img src="@/assets/img/logos/partners/double-click.svg" alt="Double Click">
                        <img src="@/assets/img/logos/partners/facebook.svg" alt="Facebook">
                        <img src="@/assets/img/logos/partners/google.svg" alt="Google">
                        <img src="@/assets/img/logos/partners/optable.svg" alt="Optable">
                        <img src="@/assets/img/logos/partners/quebecor.svg" alt="Quebecor">
                        <img src="@/assets/img/logos/partners/rogers.svg" alt="Rogers">
                        <img src="@/assets/img/logos/partners/roku.svg" alt="Roku">
                        <img src="@/assets/img/logos/partners/salesforce.svg" alt="Salesforce">
                        <img src="@/assets/img/logos/partners/samsung-ads.png" alt="Samsung Ads">
                        <img src="@/assets/img/logos/partners/tik-tok.svg" alt="TikTok">
                        <img src="@/assets/img/logos/partners/vividata.png" alt="Vivi Data">

                        <img src="@/assets/img/logos/partners/amazon.svg" alt="Amazon">
                        <img src="@/assets/img/logos/partners/android-tv.svg" alt="AndroidTV">
                        <img src="@/assets/img/logos/partners/arima.png" alt="Arima">
                        <img src="@/assets/img/logos/partners/bell-media.svg" alt="Bell Media">
                        <img src="@/assets/img/logos/partners/cloud-raker.png" alt="Cloud Raker">
                        <img src="@/assets/img/logos/partners/double-click.svg" alt="Double Click">
                        <img src="@/assets/img/logos/partners/facebook.svg" alt="Facebook">
                        <img src="@/assets/img/logos/partners/google.svg" alt="Google">
                        <img src="@/assets/img/logos/partners/optable.svg" alt="Optable">
                        <img src="@/assets/img/logos/partners/quebecor.svg" alt="Quebecor">
                        <img src="@/assets/img/logos/partners/rogers.svg" alt="Rogers">
                        <img src="@/assets/img/logos/partners/roku.svg" alt="Roku">
                        <img src="@/assets/img/logos/partners/salesforce.svg" alt="Salesforce">
                        <img src="@/assets/img/logos/partners/samsung-ads.png" alt="Samsung Ads">
                        <img src="@/assets/img/logos/partners/tik-tok.svg" alt="TikTok">
                        <img src="@/assets/img/logos/partners/vividata.png" alt="Vivi Data">

                        <img src="@/assets/img/logos/partners/amazon.svg" alt="Amazon">
                        <img src="@/assets/img/logos/partners/android-tv.svg" alt="AndroidTV">
                        <img src="@/assets/img/logos/partners/arima.png" alt="Arima">
                        <img src="@/assets/img/logos/partners/bell-media.svg" alt="Bell Media">
                        <img src="@/assets/img/logos/partners/cloud-raker.png" alt="Cloud Raker">
                        <img src="@/assets/img/logos/partners/double-click.svg" alt="Double Click">
                        <img src="@/assets/img/logos/partners/facebook.svg" alt="Facebook">
                        <img src="@/assets/img/logos/partners/google.svg" alt="Google">
                        <img src="@/assets/img/logos/partners/optable.svg" alt="Optable">
                        <img src="@/assets/img/logos/partners/quebecor.svg" alt="Quebecor">
                        <img src="@/assets/img/logos/partners/rogers.svg" alt="Rogers">
                        <img src="@/assets/img/logos/partners/roku.svg" alt="Roku">
                        <img src="@/assets/img/logos/partners/salesforce.svg" alt="Salesforce">
                        <img src="@/assets/img/logos/partners/samsung-ads.png" alt="Samsung Ads">
                        <img src="@/assets/img/logos/partners/tik-tok.svg" alt="TikTok">
                        <img src="@/assets/img/logos/partners/vividata.png" alt="Vivi Data">
                    </div>
                </div>
            </div>
        </div>

        <logos-svg-cut />

        <section id="work" class="work max-padding">
          <div class="wrap-title center" data-scroll data-inview="fadeInUp" data-delay="100">
            <h2 class="title" v-if="$route.meta.lang === 'en'"><span>{{ $t("Our Work") }}</span><br>{{ $t("Innovation Delivering Value") }}</h2>
            <h2 class="title" v-if="$route.meta.lang === 'fr'">{{ $t("Innovation Delivering Value") }}<br><span>{{ $t("Our Work") }}</span></h2>
          </div>
          <div class="blocs">
            <a :href="$router.resolve({ name: 'Instacart-' + $route.meta.lang }).href" class="bloc bloc-3" data-scroll data-inview="fadeInUp" data-delay="200">
              <div class="img">
                <img src="@/assets/img/instacart-logo.svg" class="logo" alt="Instacart logo">
                <div class="overlay"></div>
                <div class="line"></div>
                <img src="@/assets/img/instacart-img.jpg" class="cover" alt="Instacart">
              </div>
              <div class="name">
                <p class="regular-text">{{ $t("Develop a New Market") }}</p>
                <img src="@/assets/img/arrow-right.svg" class="arrow" alt="Arrow right">
              </div>
            </a>
            <a :href="$router.resolve({ name: 'Gardasil-' + $route.meta.lang }).href" class="bloc bloc-3" data-scroll data-inview="fadeInUp" data-delay="300">
              <div class="img">
                <img src="@/assets/img/gardasil-logo.png" class="logo" alt="Gardasil logo">
                <div class="overlay"></div>
                <div class="line"></div>
                <img src="@/assets/img/gardasil-img.jpg" class="cover" alt="Gardasil 9">
              </div>
              <div class="name">
                <p class="regular-text">{{ $t("Increase Vaccinations") }}</p>
                <img src="@/assets/img/arrow-right.svg" class="arrow" alt="Arrow right">
              </div>
            </a>
            <a :href="$router.resolve({ name: 'NotCo-' + $route.meta.lang }).href" class="bloc bloc-3" data-scroll data-inview="fadeInUp" data-delay="400">
              <div class="img">
                <img src="@/assets/img/logos/not-co.png" class="logo" alt="NotCo logo">
                <div class="overlay"></div>
                <div class="line"></div>
                <img src="@/assets/img/work-3.jpg" class="cover" alt="Media Innovation">
              </div>
              <div class="name">
                <p class="regular-text">{{ $t("New Product Launch") }}</p>
                <img src="@/assets/img/arrow-right.svg" class="arrow" alt="Arrow right">
              </div>
            </a>
          </div>
        </section>

        <section id="careers" class="careers-bloc max-padding">
          <div class="img" data-scroll data-inview="revealRight" data-delay="200">
            <div class="line"></div>
            <img src="@/assets/img/careers-home.jpg" alt="Panoply team">
          </div>
          <div class="data" data-scroll data-inview="fadeIn" data-delay="400">
            <h3 class="small-title">{{ $t("Our Culture") }}</h3>
            <h4 class="medium-title">{{ $t("Come as you are.") }}</h4>
            <p class="regular-text">{{ $t("We are digital-first, multiethnic, multilingual, curious and flexible. We are hiring innovators looking to build the future.") }}</p>
            <a href="https://gopanoply.breezy.hr/" target="_blank" class="cta" rel="noopener noreferer">
              <p>{{ $t("Join our team") }}</p>
              <img src="@/assets/img/arrow-right.svg" class="arrow" alt="Arrow right">
            </a>
          </div>
        </section>
    </div>
</template>

<script>
import MethodologyGraph from '@/components/MethodologyGraph.vue'
import MediaExpertisesSlider from '@/components/MediaExpertisesSlider.vue'
import HeroSvgCut from '@/components/svg-cut/HeroSvgCut.vue'
import LogosSvgCut from '@/components/svg-cut/LogosSvgCut.vue'

import { initLocoScroll } from '@/plugins/LocoScroll'
import { loadAssets } from '@/plugins/AssetsLoader'

export default {
    name: 'Home',

    components: {
        MethodologyGraph,
        HeroSvgCut,
        LogosSvgCut,
        MediaExpertisesSlider
    },

    data() {
      return {}
    },

    mounted() {
        
    },

    watch: {
        $route: {
            handler($route) {
                const scroll = initLocoScroll()

                // Page load buffer
                setTimeout(() => {
                    scroll.update()

                    const defaultSrc = document.querySelector('[data-hero-svg-anim]').getAttribute('data-src')
                    const tt = new Date().valueOf()
                    document.querySelector('[data-hero-svg-anim]').setAttribute('src', `${defaultSrc}?tt=${tt}`)
                    document.querySelector('[data-hero-svg-anim]').classList.add('is-active')

                    setTimeout(() => {
                        scroll.update()
                    }, 3155)
                }, 755)

                // ReUpdate LocoScroll after assets load
                loadAssets('[data-scroll-container]', () => {
                    scroll.update()
                })
                
                if ($route && $route.hash) {
                    setTimeout(() => {
                        const $zone = document.querySelector($route.hash.replace('a-', ''))
                        // this changes the scrolling behavior to "smooth"
                        scroll.scrollTo($zone)
                    }, 1175)
                }
            },
            immediate: true,
        },
    },
}
</script>
